export enum EnumSystemRoleType {
  ADMINISTRATOR = 'ADMINISTRATOR',
  OPERATING = 'OPERATING',
  ACCOUNTING = 'ACCOUNTING',
  CARDHOLDER = 'CARDHOLDER',
}

export interface SystemRoles {
  id: string;
  type: EnumSystemRoleType;
  description: string;
  createdAt: string;
  updatedAt: string;
}
