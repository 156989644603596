import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { EnumSystemRoleType, SystemRoles } from '@/types/system-roles';

export type TSystemRoles = Record<EnumSystemRoleType, SystemRoles | undefined>;

interface SystemRolesStore {
  systemRoles: TSystemRoles;
  setRoles: (systemRoles: TSystemRoles) => void;
}

export const useSystemRoles = create<SystemRolesStore>()(
  devtools(
    persist(
      (set) => ({
        systemRoles: {
          [EnumSystemRoleType.ADMINISTRATOR]: undefined,
          [EnumSystemRoleType.OPERATING]: undefined,
          [EnumSystemRoleType.ACCOUNTING]: undefined,
          [EnumSystemRoleType.CARDHOLDER]: undefined,
        },
        setRoles: (systemRoles: TSystemRoles) =>
          set({
            systemRoles,
          }),
      }),
      {
        name: 'system-roles',
        getStorage: () => localStorage,
      },
    ),
  ),
);
